import {Box, Container, Stack, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import {Footer} from "./Footer";
import {APIurl} from "../utils/Utils";
import {useSearchParams} from "react-router-dom";


export function Qr() {

    const [searchParams, _setSearchParams] = useSearchParams();
    const instagramAppLink = "instagram://user?username=sarannopurecazzimiei";
    const instagramWebLink = "https://www.instagram.com/sarannopurecazzimiei";

    useEffect(() => {
        const id = searchParams.get("id") === null ? "qr" : searchParams.get("id")
        addScan(id).then(() => {})
    }, [searchParams])


    async function addScan(id) {
        try {
            console.log(id)
            await fetch(`${APIurl}/addScan/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
        } catch (e) {
            console.error(e)
        } finally {
            redirectToInstagram(instagramAppLink, instagramWebLink);
        }
    }

    function redirectToInstagram(appLink, webLink) {
        // Prova ad aprire il link dell'app
        const timeout = setTimeout(() => {
            // Se dopo un timeout l'app non si apre, effettua il redirect al link web
            window.location.href = webLink;
        }, 1000);

        // Crea un anchor per tentare di aprire il link dell'app
        const anchor = document.createElement("a");
        anchor.href = appLink;
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();

        // Rimuovi il timeout se l'app viene aperta
        window.addEventListener("blur", () => clearTimeout(timeout), { once: true });
    }

    return (
        <Box sx={{height:"100vh"}}>
            <Container sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", textAlign:"center", py:2}}>
                <Box />
                <Box sx={{width:{xs:"100%", md:"70%", lg:"50%"}}}>
                    <Stack>
                        <Typography level="h1" textTransform="uppercase" fontWeight="bold" sx={{wordBreak: "break-all"}}>
                            SarannoPureCazziMiei
                        </Typography>
                    </Stack>
                </Box>
                <Footer />
            </Container>
        </Box>
    )
}
