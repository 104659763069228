import {
    Box,
    Container,
    Grid,
    Input,
    Select,
    Stack,
    Option,
    Typography,
    Button,
    IconButton,
    FormControl, FormLabel, Textarea
} from "@mui/joy";
import {useState} from "react";
import {Delete} from "@mui/icons-material";
import {nanoid} from "nanoid";
import QRCode from "qrcode";
import {APIurl} from "../utils/Utils";
const { PDFDocument, StandardFonts, rgb } = require('pdf-lib');


export function GenerateQr() {

    const [stickers, setStickers] = useState([])
    const [allStickers, setAllStickers] = useState([])
    const initialSticker = {
        id: "",
        domanda1: "",
        domanda2: "",
        domanda3: "",
        offset: 10,
        pezzi: 0,
        colore: "",
    }
    const [newSticker, setNewSticker] = useState(initialSticker)

    return (
        <Box sx={{height:"100vh", width:"100%"}}>
            <Grid container spacing={2} sx={{minHeight:"100vh", width:"100%"}}>
                <Grid xs={3} py={5}>
                    <Container sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", height:"100%"}}>
                        <Stack direction="column" spacing={2}>
                            <Typography level="h2" fontWeight="bold">Elenco sticker</Typography>
                            {
                                stickers.length > 0 &&
                                stickers.map((item, index) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} key={index}>
                                            <Stack direction="column" spacing={0}>
                                                <Typography level="title-md" fontWeight="bold">
                                                    {item.domanda1} {item.domanda2} {item.domanda3}
                                                </Typography>
                                                <Typography level="body-sm">Pezzi: {item.pezzi}</Typography>
                                                <Typography
                                                    level="body-sm">Colore: {item.colore === "b" ? "Nero" : "Bianco"}</Typography>
                                                <pre style={{margin: 0, padding: 0, paddingTop:3, opacity: 0.2}}>ID: {item.id}</pre>
                                            </Stack>
                                            <IconButton onClick={() => {
                                                if(window.confirm(`Eliminare "${item.domanda1}"?`)) {
                                                    setStickers(stickers.filter(a => a.id!==item.id))
                                                }
                                            }}>
                                                <Delete/>
                                            </IconButton>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>

                        <Grid container spacing={1}>
                            <Grid xs={12}>
                                <Typography level="h4" fontWeight="bold">Nuovo sticker</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <FormControl size="sm">
                                    <FormLabel>Domanda</FormLabel>
                                    <Input type="text"
                                           placeholder="Riga 1"
                                           size="sm"
                                           value={newSticker.domanda1}
                                           onChange={e => setNewSticker({...newSticker, domanda1: e.target.value})}
                                    />
                                </FormControl>
                                <Input type="text"
                                       placeholder="Riga 2"
                                       size="sm"
                                       sx={{mt:1}}
                                       value={newSticker.domanda2}
                                       onChange={e => setNewSticker({...newSticker, domanda2: e.target.value})}
                                />
                                <Input type="text"
                                       placeholder="Riga 3"
                                       size="sm"
                                       sx={{mt:1}}
                                       value={newSticker.domanda3}
                                       onChange={e => setNewSticker({...newSticker, domanda3: e.target.value})}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <FormControl size="sm">
                                    <FormLabel>Pezzi</FormLabel>
                                    <Input type="number"
                                           value={newSticker.pezzi}
                                           onChange={e => setNewSticker({...newSticker, pezzi: Number(e.target.value)})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={6}>
                                <FormControl size="sm">
                                    <FormLabel>Offset</FormLabel>
                                    <Input type="number"
                                           value={newSticker.offset}
                                           onChange={e => setNewSticker({...newSticker, offset: Number(e.target.value)})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12}>
                                <FormControl size="sm">
                                    <FormLabel>Colore</FormLabel>
                                    <Select size="sm"
                                            value={newSticker.colore}
                                            onChange={(e, newValue) => setNewSticker({...newSticker, colore: newValue})}
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        <Option value="w">Bianco</Option>
                                        <Option value="b">Nero</Option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12}>
                                <Button onClick={() => {
                                    let stick = newSticker
                                    stick.id = nanoid()
                                    setStickers(oldStickers => [...oldStickers, stick])
                                    setNewSticker(initialSticker)
                                }}
                                        disabled={newSticker.pezzi === 0 || newSticker.colore === "" || newSticker.domanda1.trim() === ""}
                                        sx={{width: '100%'}}
                                >
                                    Aggiungi
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid xs={9} py={5}>
                    <Container>
                        <Stack direction="column" spacing={2}>
                            <Typography level="h2" fontWeight="bold">Immagini</Typography>
                            <Button onClick={async () => {
                                await generaImmagini()
                            }}>
                                GENERA PDF
                            </Button>
                            {
                                allStickers.length !== 0 ?
                                    <Textarea
                                        value={getStringTextArea(allStickers)}
                                    />
                                    :
                                    null
                            }
                        </Stack>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )

    async function generaImmagini() {
        const countStickers = stickers.reduce((partialSum, a) => partialSum + a.pezzi, 0);
        console.log(countStickers);
        const response = await fetch(`${APIurl}/getFreeStickerIds/${countStickers}/a`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        if (response.ok) {
            const data = await response.json()
            let ids = data.map(a => a)
            let index = 0

            const baseUrl = "https://www.sarannopurecazzimiei.it/qr?id="
            let allStick = []
            stickers.map(async (item, index) => {
                for (let i = 0; i < item.pezzi; i++) {
                    const id = ids[index]
                    const link = baseUrl + id
                    QRCode.toDataURL(link, /*{errorCorrectionLevel: 'H'},*/ function (err, url) {
                        const stick = {
                            id: id,
                            domanda1: item.domanda1,
                            domanda2: item.domanda2,
                            domanda3: item.domanda3,
                            offset: item.offset,
                            colore: item.colore,
                            qr: url
                        }
                        allStick.push(stick)
                    })
                    index ++
                }
            })
            let sticksBianchi = allStick.filter(a => a.colore === "w")
            let sticksNeri = allStick.filter(a => a.colore === "b")
            if (sticksBianchi.length !== sticksNeri.length) {
                if (window.confirm("Il numero degli sticker bianchi è diverso da quello degli sticker neri. Confermare?")) {
                    setAllStickers(allStick)
                    await createPDF(allStick, true)
                } else {
                    setAllStickers([])
                }
            } else {
                let stickerfinal = []
                for (let i = 0; i < sticksBianchi.length; i++) {
                    stickerfinal.push(sticksBianchi[i])
                    stickerfinal.push(sticksNeri[i])
                }
                setAllStickers(stickerfinal)
                await createPDF(stickerfinal, false)
            }
        }
    }

    async function createPDF(stickersfinal, border) {
        const padding = 4
        // Dimensioni del documento
        const xDoc = 2046 + padding*2 + padding/2; // Larghezza in mm
        const yDoc = 1000; // Altezza in mm

        // Dimensioni sticker e contenuti
        const xSticker = 62; // Sticker: larghezza in mm
        const ySticker = 62; // Sticker: altezza in mm
        const xyQr = 25; // QR code: dimensione in mm
        const borderWidth = 0.25; // Bordo in mm
        const innerPadding = mmToPoints(9); // Margine interno (0.9 cm)
        const textSize = 24;
        const textSpacing = 1;
        const initialOffsetPage = mmToPoints(padding)

        // Creazione del documento PDF
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([mmToPoints(xDoc), mmToPoints(yDoc)]);

        const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        // Conversione delle dimensioni in punti
        const stickerWidth = mmToPoints(xSticker);
        const stickerHeight = mmToPoints(ySticker);
        const qrSize = mmToPoints(xyQr);

        const stickersPerRow = Math.floor(xDoc / xSticker);
        const stickersPerCol = Math.floor(yDoc / ySticker);

        let currentStickerIndex = 0;

        // Aggiungi adesivi alla pagina
        for (let row = 0; row < stickersPerCol; row++) {
            for (let col = 0; col < stickersPerRow; col++) {
                if (currentStickerIndex >= stickersfinal?.length) break;

                const stick = stickersfinal[currentStickerIndex];

                // Calcolo della posizione a partire dall'angolo in alto a sinistra
                const x = col * stickerWidth + initialOffsetPage;
                const y = page.getHeight() - (row + 1) * stickerHeight - initialOffsetPage;

                // Disegna lo sfondo dello sticker
                page.drawRectangle({
                    x: x,
                    y: y,
                    width: stickerWidth,
                    height: stickerHeight,
                    color: stick.colore === "b" ? rgb(0, 0, 0) : rgb(1, 1, 1),
                    borderWidth: border ? borderWidth : 0,
                    borderColor: rgb(0.25, 0.25, 0.25),
                });

                // Testo centrato verticalmente e orizzontalmente
                const text1 = stick.domanda1;
                const text2 = stick.domanda2 === "" ? null : stick.domanda2;
                const text3 = stick.domanda3 === "" ? null : stick.domanda3;
                const textArray = [text1, text2, text3]

                for (let j = 0; j < textArray.length; j++) {
                    const t = textArray[j];
                    if(t) {
                        const textWidth = font.widthOfTextAtSize(t, textSize);
                        const textX = x + (stickerWidth - textWidth) / 2;
                        const textY = y + stick.offset + stickerHeight / 2 + textSize / 2 + innerPadding - (textSize+textSpacing)*(j);

                        page.drawText(t, {
                            x: textX,
                            y: textY,
                            size: textSize,
                            font: font,
                            color: stick.colore === "b" ? rgb(1, 1, 1) : rgb(0, 0, 0),
                        });
                    }
                }

                // QR code centrato verticalmente sotto il testo
                const qrX = x + (stickerWidth - qrSize) / 2;
                const qrY = y + (stickerHeight / 2) - qrSize - textSize / 2 + innerPadding/2 ;

                const qrImage = await pdfDoc.embedPng(stick.qr);
                page.drawImage(qrImage, {
                    x: qrX,
                    y: qrY,
                    width: qrSize,
                    height: qrSize,
                });

                currentStickerIndex++;
            }
        }

        // Salva il PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        window.open(url);
    }

    // Funzione di conversione mm -> punti
    function mmToPoints(mm) {
        const pointsPerMillimeter = 2.83465; // 1 punto = 0.3528 mm
        return Math.round(mm * pointsPerMillimeter);
    }

    function getStringTextArea(qrcodes) {
        let string = "[ "
        qrcodes.forEach(qrcode => {
            string += "\"" + qrcode.id + "\", ";
        })
        string = string.slice(0,-1)
        string = string.slice(0,-1)
        string += " ]"
        return string
    }
}